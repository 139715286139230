import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  ko: {
    translation: {
      notFound: "정상적인 접근을 시도해 주세요.",
      back: "뒤로가기",
      home: "홈",
      introduce: "회사 소개",
      partner: "관계사 소개",
      business: "사업 영역",
      news: "PR/IR",
      recruit: "인재 채용",
      cs: "고객지원",
      main01: "고객가치를 최우선으로 하는 에스씨지솔루션즈",
      main01_1: "",
      main02: "No1. Solution Provider",
      main03: "파트너와 동반 성장하는 에스씨지솔루션즈",
      main03_1: "",
      main04: "End to End Solution Provider",
      mainIt: "IT사업",
      mainSolar: "신재생에너지사업",
      mainManage: "종합관리사업",
      mainManage2_1: "종합관리사업",
      mainManage2_2: "도시가스 고객지원사업",
      mainCs: "도시가스 고객지원사업",
      mainItBusiness: "IT사업",
      mainSolarBusiness: "신재생 에너지사업",
      mainManageBusiness: "종합관리사업",
      mainCsBusiness: "도시가스 고객지원사업",
      solution: "솔루션",
      cloud: "클라우드",
      ito: "ITO",
      solar: "태양광 발전",
      buildingManag: "건물 종합 관리",
      gasCs: "도시가스 고객지원",
      more: "더보기",
      footerLogo: "에스씨지솔루션즈(주)",
      footerPolicy1: "윤리경영",
      footerPolicy2: "개인정보처리방침",
      policyTitle: "개인정보취급방침",
      footerSiteMap: "사이트맵",
      footerCs: "고객지원",
      footerPost: "소리함",
      footerDownload: "자료실",
      footerAddressKey: "주소",
      footerAddressValue: "서울시 송파구 법원로6길 11 환인빌딩 5층",
      footerCsCenterKey: "고객센터",
      footerCsCenterValue: "02-827-0020",
      footerEmailKey: "E-Mail",
      footerEmailValue: "admin@scgs.co.kr",
      footerTelKey: "Tel",
      footerTelValue: "1811-7247",
      footerCopyrightKey: "Copyright by",
      footerCopyrightValue: "SCG Solutions Co., Ltd. All Rights Reserved.",
      footerBusinessRegistrationKey: "사업자등록번호",
      footerBusinessRegistrationValue: "109-86-20739",
      footerCeoKey: "대표이사",
      footerCeoValue: "남성능",
      introMain01: "고객가치를 최우선으로 하는 에스씨지솔루션즈",
      introMain01_1: "",
      introMain02: "서울도시가스그룹 IT 전문기업 에스씨지솔루션즈", // 분할
      introMain02_1:
        "글로벌 No.1 솔루션들을 전략적으로 확보해가고 있으며 국내외 법인 설립 및", // 분할
      introMain02_2:
        "투자를 통해 사업영역을 지속적으로 확대해 나아가고 있습니다.", // 분할
      introInfoScale: "매출규모",
      introInfoScaleValue: "2,883 억",
      introInfoWorker: "직원",
      introInfoWorkerValue: "628 명",
      introInfoPartner: "파트너",
      introInfoPartnerValue: "1,484 업체",
      introInfoCompanyName: "회사명",
      introInfoCompanyNameValue: "에스씨지솔루션즈",
      introInfoFoundingDate: "설립일",
      introInfoFoundingDateValue: "`09년 11월",
      introInfoComment: "*23.12 기준",
      introInfoBusiness: "사업영역",
      introInfoBusinessValue1: "IT",
      introInfoBusinessValue2: "신재생에너지",
      introInfoBusinessValue3: "종합관리",
      introInfoBusinessValue4: "고객지원사업",
      introHistory: "연혁",
      introHistoryterm1: "2016년 ~ 현재",
      introHistoryterm2: "2009년 ~ 2015년",
      introHistoryterm3: "현재",
      introHistorySlogan1: "사업영역 확대",
      introHistorySlogan1_0: "지속성장 기반 구축",
      introHistorySlogan1_1: "Global No.1 Solution 확보",
      introHistorySlogan1_2: "영업인프라 강화",
      introHistorySlogan2: "사업기반 구축",
      introHistorySlogan2_0: "해외거점 확보",
      introHistory23_1: "TP-Link 총판",
      introHistory23_2: "Dell 컨슈머 총판",
      introHistory23_3: "Seagate Retail HDD 총판",
      introHistory22_1: "SCG Grid 설립 (대한가스기기 인수)",
      introHistory22_2: "대전지사 설립",
      introHistory22_3: "BenQ 총판 계약",
      introHistory22_4: "Arista Networks 총판 계약",
      introHistory22_5: "Salesforce NAFF CS 상담톡 개발",
      introHistory22_6:
        "Dell Technologies Distributor of the Year Service Sales FY22 Award 수상",
      introHistory20_1: "SFLO 설립",
      introHistory20_2: "Acer 파트너십 확보",
      introHistory20_3: "JinKo, JA Solar 파트너십 확보",
      introHistory19_1: "부산지사 설립",
      introHistory19_2: "Seagate 총판 계약 체결",
      introHistory19_3: "Risen Energy 총판 계약 체결",
      introHistory19_4: "Salesforce 다중채널 메시지서비스(NAFF) 개발",
      introHistory19_5: "2020 Heroes Award 수상",
      introHistory19_6: "Dell Technologies Best Distributor Award 수상",
      introHistory19_7: "Dell Technologies Shining Star Award 수상",
      introHistory19_8: "Dell Technologies Storage Growth Award 수상",
      introHistory18_1: "SNN 합병",
      introHistory18_2: "Salesforce 파트너십 확보",
      introHistory18_3: "Targus 총판 계약 체결",
      introHistory18_4: "Dell EMC Excellence in Digital Marketing Award 수상",
      introHistory17_1: "Dell EMC 총판 계약 체결",
      introHistory17_2: "Dell EMC Best Achievements Partner Award 수상",
      introHistory16_1: "Technicolor 총판",
      introHistory16_2: "Dell EUC 총판",
      introHistory16_3: "Dell Top Commercial Distributor Award 수상",
      introHistory15_1: "SCGS Dalian 설립",
      introHistory15_2: "Dell Top Distributor Award 수상",
      introHistory14_1: "Dell 총판 계약 체결",
      introHistory14_2: "Huawei 총판 계약 체결",
      introHistory13_1: "SCGS Indonesia 설립",
      introHistory13_2: "Acronis 총판 계약 체결",
      introHistory13_3: "Cisco SI Gold 파트너십 확보",
      introHistory12_1: "IBM 파트너십",
      introHistory10_1: "SCGS Thailand 설립",
      introHistory10_2: "Qlogic 총판 계약 체결",
      introHistory09_1: "SCGS 설립",
      introContact: "오시는 길",
      introPhoneNumber: "전화번호",
      introFax: "팩스",
      introLocation_1: "본사",
      introLocation_2: "용산지점",
      introLocation_3: "경기지점",
      introLocation_4: "부산지사",
      introLocation_5: "대전지사",
      introLocation_6: "용산 물류센터",
      introAddress_1: "서울 송파구 법원로6길 11 에스씨지솔루션즈(주)",
      introAddress_2: "서울시 용산구 한강대로 273 용산빌딩 4층",
      introAddress_3: "경기 고양시 일산서구 경의로 797 4층",
      introAddress_4: "부산 해운대구 센텀서로 30 KNN타워 22층, S05호",
      introAddress_5: "대전시 서구 청사로 136, 대전무역회관 18층(1806호)",
      introAddress_6: "서울시 용산구 원효로 123-10, 태경빌딩 2, 3층",
      partnerTitle: "관계사 소개",
      partnerSubTitle1: "국내 관계사",
      partnerLinkHomePage: "홈페이지 이동",
      partnerBusiness: "사업분야",
      partnerLocation: "위치",
      partnerSubTitle2: "해외 관계사",
      partnerKoDescription_1:
        "서울도시가스는 국내 최초 100만톤과 100만 가구에 가스 공급한 도시가스 공급업체로 해외자원개발 사업 등으로 사업영역을 확장함으로써 에너지 종합기업으로서의 역할을 수행하고 있습니다.",
      partnerKoDescription_2:
        "에스씨지그리드는산업용 가스미터기 부문 1위 (터빈 및 로터리) 기업으로, IoT기술이 접목된 가정용 및 산업용 가스계량 관련 제품을 개발, 생산하는 계량기 전문 제조 기업입니다.",
      partnerKoDescription_3:
        "에스씨지랩은  플랫폼 전문기업으로  국내 공공서비스앱 중 가장 높은 고객 이용률과 만족도를 가진 도시가스 통합솔루션 가스앱을 국내 주요 도시가스사에 서비스하고 있습니다.",
      partnerKoDescription_4:
        "서울씨엔지는 친환경 에너지 전문기업으로 수소, 태양광 등으로 에너지사업을 확대하고 있습니다. 또한, 천연가스 충전소를 운영하고 있으며, 국내 최초 상업충전이 가능한 충전소를 설치하였고, 서울/경기 지역에 5개 충전소와 자영충전소 12개소를 위탁운영하고 있습니다.",
      partnerKoDescription_5:
        "홈텔리어는 1인세대부터 다인세대까지 홈케어, 사무실 청소, 컨시어지 등 라이프 스타일을 지원 하는 서비스를 제공 하고 있습니다.",
      partnerKoCategory_1: "도시가스공급, 가스공급설비공사",
      partnerKoCategory_2: "가정용 및 산업용 가스계량 관련 제품 제조 및 판매",
      partnerKoCategory_3: "IT 서비스 개발 및 디지털콘텐츠 사업",
      partnerKoCategory_4: "운송장비용 천연가스 공급",
      partnerKoCategory_5: "라이프스타일 지원 서비스",
      partnerKoArea_1: "서울시 강서구 공항대로 607 (염창동, 서울도시가스사옥)",
      partnerKoArea_2: "인천광역시 남동구 고잔동 717-7",
      partnerKoArea_3: "서울특별시 마포구 월드컵북로 6길 이노베이스 빌딩 6층",
      partnerKoArea_4: "서울특별시 마포구 가양대로 125 (상암동)",
      partnerKoArea_5: "서울특별시 용산구 백범로 99길 40 트리스퀘어 101-205",
      partnerGlobalDescription_1:
        "SCGS 태국법인은 IT Solution 사업, ITO/SI 사업 및 태양광 패널 공급사업을 중심으로 사업영역을 확대하고 있습니다.",
      partnerGlobalDescription_2:
        "SCGS 대련법인은 Call Outbound 중심의 텔레세일즈 마케팅 사업을 추진하고 있습니다.",
      partnerGlobalDescription_3:
        "미국의 유전 개발 사업을 추진하고 있는 기업 입니다.",
      partnerGlobalCategory_1:
        "IT Solution 사업, ITO/SI 사업, 태양광패널 공급사업",
      partnerGlobalCategory_2: "텔레세일즈 마케팅 / 영업",
      partnerGlobalCategory_3:
        "IT Solution 사업, ITO/SI 사업, 태양광패널 공급사업",
      partnerGlobalArea_1:
        "6/10 Soi Patanasin, Pipatanasin Building, 10th Floor, Narathiwas Ratchanakharin Road, Thungmahamek, Sathorn, Bangkok 10120",
      partnerGlobalArea_2:
        "602, Shenghui B/D, No.9 Gaoxin street High-tech park, Dalian City, Liaoning Province, CH",
      partnerGlobalArea_3:
        "4925 Greenville Ave., Ste 709, Dallas, Texas 75206, USA",
      businessDescription_1:
        "SCGS는 Dell Technologies의 신뢰할 수 있는 Distributor로, 필요한 모든 솔루션을 제공합니다. SCGS를 통해Dell Technologies의 포괄적인 솔루션으로 조직의 역량을 강화해 보십시오.",
      businessDescription_2:
        "Seagate는 데이터 스토리지 솔루션 업계의 글로벌 리더로 전세계의 수많은 사람들과 기업들이 중요한 메모리와 비즈니스 데이터를 생성 공유 및 보관 할 수 있는 제품을 개발 하고 있으며 에스씨지솔루션는 스토리지, HDD, SSD 등의 제품을 제공합니다.",
      businessDescription_3:
        "클라우드 타이탄이 선택한 데이터센터 네트워킹 글로벌 리더로 Arista Networks의 한국 공식 Distributor로, 네트워킹 대세 기술을 주도 하고 있는 Arista Networks의 모든 제품과 솔루션을 제공합니다",
      businessDescription_4:
        "BenQ는 글로벌 디스플레이 브랜드로 “당신의 삶에 즐거움과 퀄리티를 높여준다” 는 모토로 에스씨지솔루션즈는 전자칠판 등의 제품을 제공합니다.",
      dellDescription:
        "Dell Technologies의 포괄적인 솔루션으로 조직의 역량을 강화해 보십시오. SCGS는 Dell Technologies의 신뢰할 수 있는 Distributor로, 필요한 모든 솔루션을 제공합니다.",
      dellDescription1:
        "Server, Storage, Network 솔루션 분야의 글로벌 리더 Dell Technologies",
      dellDescription2:
        "에스씨지솔루션즈는 Dell Technologies NO1. Distributor로 다양한 제품과 솔루션을 제공합니다.",
      dellButton_1: "Dell 견적문의",
      dellButton_2: "Dell Premier 알아보기",
      dell_1_des: "비즈니스 혁신, 적응 및 성장을 지원하는 기술 및 솔루션",
      dell_1_subDes: "비즈니스 혁신의 엔진, 새로운 PowerEdge 서버 포트폴리오",
      dell_1_con_1: "Tower Server",
      dell_1_con_1_des: "현대적 오피스 환경을 고려한 설계",
      dell_1_con_2: "Rack Server",
      dell_1_con_2_des: "보안에 부합하는 성능",
      dell_1_con_3: "Modular Server",
      dell_1_con_3_des: "IT 투자의 수명주기 가치 극대화",
      dell_2_des:
        "Dell Technologies의 소프트웨어 기반 스토리지 솔루션은 IT 를 자동화 하고 멀티클라우드 제어 및 이동성을 확보하며 안전한 혁신을 지원합니다.",
      dell_2_subDes: "강력한 성능의 소프트웨어 기반스토리지 솔루션",
      dell_2_con_1: "Dell Technologies 백업",
      dell_2_con_1_des:
        "강력한 성능의 아키텍처, 간편한 운영, 신뢰할 수 있는 혁신",
      dell_2_con_2: "Dell Technologies 네트워크",
      dell_2_con_2_des:
        "모든 운영 규모에서 간편하게 관리, 모든 데이터를 처리하여 어디서나 지능적인 통찰력 제공",
      dell_2_con_3: "Dell Technologies 하이퍼컨버지드 인프라스트럭처",
      dell_2_con_3_des:
        "지리적으로 분산된 클라우드 스케일 오브젝트 스토리지입니다",
      dell_2_con_4: "Dell Technologies 백업",
      dell_2_con_4_des:
        "강력한 성능의 아키텍처, 간편한 운영, 신뢰할 수 있는 혁신",
      dell_2_con_5: "Dell Technologies 네트워크",
      dell_2_con_5_des:
        "모든 운영 규모에서 간편하게 관리, 모든 데이터를 처리하여 어디서나 지능적인 통찰력 제공",
      dell_2_con_6: "Dell Technologies 하이퍼컨버지드 인프라스트럭처",
      dell_2_con_6_des:
        "지리적으로 분산된 클라우드 스케일 오브젝트 스토리지입니다",
      dell_3_subDes:
        "No.1 Dell Technologies 총판 에스씨지솔루션즈는 Dell Technologies의 전 제품군을 공급하고 있는 End-to-end Solution Provider 입니다.",
      dell_3_con_1: "Display",
      dell_3_con_1_des: "내용",
      dell_3_con_2: "Desktop",
      dell_3_con_2_des: "내용",
      dell_3_con_3: "Workstation",
      dell_3_con_3_des: "내용",
      dell_3_con_4: "Notebook",
      dell_3_con_4_des: "내용",
      dell_3_con_5: "Tower/Rack Workstation",
      dell_3_con_5_des: "내용",
      dell_3_con_6: "Accessories",
      dell_3_con_6_des: "내용",
      seagateDescription:
        "SCG Solutions with, Seagate 데이터 스토리지 솔루션 업계의 글로벌 리더 Seagate의 총판 에스씨지솔루션즈는 Seagate HDD, SSD를 공급하는 공식 총판 입니다.",
      seagateDescription1: "데이터 스토리지 솔루션 업계의 글로벌 리더 Seagate",
      seagateDescription2:
        "에스씨지솔루션즈는 Seagate의 신뢰할 수 있는 Distributor로 스토리지, HDD, SSD 등의 전제품을 제공합니다.",
      seagateButton_1: "Seagate Storage 문의",
      seagateButton_2: "Seagate 구매하기",
      seagateButton_3: "CCTV 보안",
      seagate_1_des: "Seagate HDD, SSD를 공급하는 공식 총판 입니다.",
      seagate_1_subDes:
        "상시작동 성능, 안정성 및 신뢰성을 제공하도록 설계되었습니다.",
      seagate_1_con_1: "BarraCuda",
      seagate_1_con_1_des: "바라쿠다 설명",
      seagate_1_con_2: "FireCuda",
      seagate_1_con_2_des: "파이어쿠다 설명",
      seagate_1_con_3: "SkyHawk",
      seagate_1_con_3_des: "스카이 설명",
      seagate_1_con_4: "IronWolf HDD",
      seagate_1_con_4_des: "울프 설명",
      seagate_1_con_5: "Exos",
      seagate_1_con_5_des: "엑소 설명",
      seagate_1_con_6: "Nytro",
      seagate_1_con_6_des: "니트로 설명",
      seagate_2_title: "외장하드",
      seagate_2_des:
        "유니크한 디자인, 컴팩트한 사이즈의 작고 가벼운 휴대용 SSD",
      seagate_2_subDes:
        "휴대가 편리하며 대량의 콘텐츠를 저장하기에 충분한 용량을 제공하는 휴대용 하드",
      seagate_2_con_1: "Fast One Touch SSD",
      seagate_2_con_1_des:
        "유니크한 디자인, 컴팩트한 사이즈의 작고 가벼운 휴대용 SSD",
      seagate_2_con_2: "One Touch HDD",
      seagate_2_con_2_des:
        "휴대가 편리하며 대량의 콘텐츠를 저장하기에 충분한 용량을 제공하는 휴대용 하드",
      seagate_2_con_3: "One Touch Desktop Hub",
      seagate_2_con_3_des:
        "최대 20TB까지 다양한 용량으로 제공되며 여럿 플랫폼간의 호환성이 보장된 데스크탑 외장하드",
      seagate_2_con_4: "Rugged SSD USB-C",
      seagate_2_con_4_des:
        "창작 전문가를 위한 스토리지 극강의 보안시스템을 가지고 있는 외장 SSD/ 외장 하드",
      seagate_3_des:
        "페타바이트에서 엑사바이트까지 최고의 용량을 구현할 수 있으며, 펌웨어 및 멀티코어 기능을 갖추고 최고의 파트너들과 호환성을 구현합니다.",
      seagate_3_subDes:
        "신뢰성, 합리적인 가격 및 편의성을 기반으로 구축된 데이터 스토리지 솔루션",
      seagate_3_con_1: "CORVAULT",
      seagate_3_con_1_des: "설명",
      seagate_3_con_2: "All-Flash Disk Arrays",
      seagate_3_con_2_des: "설명",
      seagate_3_con_3: "Converged Platforms",
      seagate_3_con_3_des: "설명",
      seagate_3_con_4: "Expansion 및 JBOD",
      seagate_3_con_4_des: "설명",
      benqDescription:
        "BenQ는 '당신의 삶에 즐거움과 퀄리티를 높여준다(Bringing Enjoyment & Quality to Life)' 는 비전아래 BenQ 총판 에스씨지솔루션즈는 전자칠판을 공급하는 공식 총판 입니다.",
      benqDescription1: "글로벌 게이밍 디스플레이 및 전자칠판 대표 브랜드 BenQ",
      benqDescription2:
        "에스씨지솔루션즈는 BenQ의 공식 Distributor로 전자칠판 제품을 제공합니다.",
      benqButton_1: "BenQ 전자칠판 견적문의",
      benq_1_title: "전자칠판",
      benq_1_des: "전자칠판 설명",
      benq_1_subDes: "전자칠판 소개 문구",
      benq_1_con_1: "기업용 전자칠판",
      benq_1_con_1_des:
        "기업용 전자칠판(IFP)은 회의실의 다양한 기능을 활용하고 자유롭게 아이디어를 공유하는 회의 환경을 제공함",
      benq_1_con_2: "교육용 전자칠판",
      benq_1_con_2_des:
        "BenQ의 전자칠판(IFP)은 사용하기 편리한 사용자 인터페이스를 갖추고 교실에서 바로 사용할 수 있도록 최적화된 솔루션으로, 교실에서 건강하고 협동 학습을 경험할 수 있도록 수업을 지원합니다.",
      benq_1_con_3: "주변기기",
      aristaDescription:
        "에스씨지솔루션즈㈜ 는 클라우드 타이탄이 선택한 데이터센터 네트워킹 리더 ‘아리스타 네트웍스’의 국내 No.1 총판 입니다",
      aristaDescription1:
        "데이터센터 네트워킹 분야를 주도하고 있는 글로벌 리더사 Arista Networks",
      aristaDescription2:
        "에스씨지솔루션즈는 Arista Networks의 공식 Distributor로 모든 제품과 솔루션을 제공합니다.",
      aristaButton_1: "ARISTA 견적문의",
      arista_1_title: "Line-Up",
      arista_1_des:
        "제품 데이터센터/특수/딥버퍼/캠퍼스/이더넷/관리, 운영 자동화 솔루션 설명",
      arista_1_subDes: "arista 소개 문구",
      arista_1_brochure: "브로슈어",
      arista_1_con_1: "고속 데이터센터 제품군",
      arista_1_con_1_des: "설명",
      arista_1_con_2: "일반 이더넷 제품군",
      arista_1_con_2_des: "설명",
      arista_1_con_3: "캠퍼스 제품군",
      arista_1_con_3_des: "설명",
      arista_1_con_4: "딥버퍼 제품군",
      arista_1_con_4_des: "설명",
      arista_1_con_5: "특수 제품군",
      arista_1_con_5_des: "설명",
      arista_1_con_6: "관리, 운영 자동화 솔루션",
      arista_1_con_6_des: "설명",
      tplinkDescription: "설명",
      tplinkDescription1:
        "전 세계 Wi-Fi 제품 시장 점유율 1위의 글로벌 기업 tp-link",
      tplinkDescription2:
        "에스씨지솔루션즈는 tp-link의 공식 Distributor로 가정용, 기업용 네트워크 장비 등의 전 제품을 제공합니다.",
      tplink_1_title: "Omada 네트워크",
      tplink_1_con_1: "EAP610-Outdoor",
      tplink_1_con_2: "EAP670",
      tplink_1_con_3: "ER8411",
      tplink_1_con_1_des: "설명",
      tplink_1_con_2_des: "설명",
      tplink_1_con_3_des: "설명",
      tplink_2_title: "CCTV 보안감시",
      tplink_2_con_1: "Tapo C220",
      tplink_2_con_2: "Tapo C110",
      tplink_2_con_3: "Tapo C425",
      tplink_2_con_4: "VIGI C540V",
      tplink_2_con_5: "VIGI NVR1104H-4P",
      tplink_2_con_6: "VIGI NVR2016H",
      tplink_2_con_1_des: "설명",
      tplink_2_con_2_des: "설명",
      tplink_2_con_3_des: "설명",
      tplink_2_con_4_des: "설명",
      tplink_2_con_5_des: "설명",
      tplink_2_con_6_des: "설명",
      cloudDescription:
        "세일즈포스 솔루션 데이터를 모으면 영업의 방향이 보입니다.",
      cloudDescription_1: "세계 No.1 SaaS 기반 CRM 솔루션 Salesforce",
      cloudDescription_2:
        "에스씨지솔루션즈는 고객과 더욱 밀접하게 연결하여 성공적인 비즈니스를 돕는 세일즈포스의 컨설팅파트너 입니다.",
      cloud_1_con_1: "업무프로세스를 확립하고 영업팀의 능력을 향상시켜줍니다.",
      cloud_1_con_1_des:
        "회사의 모든 영업활동을 고객 360도, 파이프라인, 단일 플랫폼 기반으로 통합관리하고",
      cloud_1_con_2_des:
        "상호연결하여 영업 프로세스를 표준화/자동화 할 수 있습니다.",
      cloud_2_con_1:
        "다양한 채널의 고객서비스를 통합관리 할 수 있으며 고객대응을 위한 Knowledge 관리가 가능합니다.",
      cloud_2_con_1_des:
        "모든 채널에서 지속적인 커뮤니케이션을 실현하는 고객 서비스 플랫폼",
      cloud_3_con_1: "새로운 형태로 고객과 연결하여 1:1 고객경험을 실현합니다.",
      cloud_3_con_1_des:
        "메시지, 이메일, SNS 등을 통해 실시간으로 고객반응을 파악하여개인화된 마케팅활동을 효과적으로 지원",
      naffDescription:
        "SCGS의 NAFF(다중채널메시지 서비스)는 단순한 문자메시지 서비스가 아닌 고객 정보를 실시간 운영 가능한 확장형 다중채널 메시지 서비스입니다.",
      naffDescription_1:
        "에스씨지솔루션즈가 만든 Salesforce Add-On Package, NAFF",
      naffDescription_2:
        "에스씨지솔루션즈는 고객과 더욱 밀접하게 연결하여 성공적인 비즈니스를 돕는 세일즈포스의 컨설팅파트너 입니다.",
      naff_1_title: "다중채널메시지 패키지",
      naff_1_con_1:
        "고객 정보를 실시간 운영 가능한 확장형 다중채널 메시지 서비스입니다.",
      naff_1_con_1_des:
        "카카오톡과의 연동을 통해 마케팅 업무의 효율성을 향상시켜줍니다.",
      naff_2_title: "카카오 상담톡 패키지",
      naff_2_con_1:
        "Core Cloud와 상담톡을 연동하여 CS업무의 효율성을 높여줄 수 있는 패키지 서비스입니다.",
      naff_2_con_1_des:
        "고객과 실시간 상담, 상담 결과 등을 보고서 및 대시보드로 편리하게 확인 가능",
      itoDescription: "에너지기업 중심의 시스템 개발 및 유지보수, ITO 수행",
      ito_1_title: "ITO 사업",
      ito_1_sub_1_list_1: "시스템 유지보수",
      ito_1_sub_1_list_2: "시스템 개발",
      ito_1_sub_1_list_3: "IT 인프라 구축",
      ito_1_sub_1_list_4: "컨설팅 및 ITO 수행",
      ito_1_sub_2_title_1: "주요 프로젝트",
      ito_1_sub_2_list_1: "AMI계량기 MDMS시스템 구축",
      ito_1_sub_2_list_2: "고객센터 현장용 모바일시스템 고도화",
      ito_1_sub_2_list_3: "통합정보시스템 고도화 구축",
      ito_1_sub_2_list_4: "CNG POS 시스템 구축",
      ito_1_sub_2_list_5: "통합 콜 센터 고도화 구축 및 유지보수",
      ito_1_sub_2_list_6: "서울도시가스 정보시스템 통합유지보수",
      ito_2_title: "주요 고객사",
      ito_2_con_1: "서울도시가스주식회사",
      ito_2_con_2: "SCGLAB",
      ito_2_con_3: "서울씨엔지(주)",
      ito_2_con_4: "JB(주)",
      ito_2_con_5: "홈텔리어",
      ai_main_title_1:
        "비지니스 전반에 최적화된 AI Factory Starter Kit 제공해 드립니다.",
      ai_main_title_2: "",
      ai_header_2: "AI Factory 추천모델",
      ai_1_con_1_des: "중규모 이상 모델 학습 및 추론",
      ai_2_con_1_des: "소규모 모델 학습",
      ai_2_con_2_des: "영상 분석 및 학습",
      ai_p2_li_header_1: "주요 기능",
      ai_p2_li_header_2: "해당 모델 및 사양",
      ai_p2_header_1: "GPU 고집적 서버",
      ai_p2_gpuDataList_1_case: "HPC/AI/ML/DL 훈련 생성형 AI",
      ai_p2_gpuDataList_1_detail_1: "대규모 추천 엔진 훈련",
      ai_p2_gpuDataList_1_detail_2: "모델링 및 시뮬레이션",
      ai_p2_gpuDataList_2_case: "HPC/AI/데이터베이스/ 분석",
      ai_p2_gpuDataList_2_detail_1: "AI/ML/DL 훈련 및 추론",
      ai_p2_gpuDataList_2_detail_2: "중간 규모 데이터 세트 언어 모델",
      ai_p2_gpuDataList_2_detail_3: "이미지 인식",
      ai_p2_gpuDataList_2_detail_4: "모델링 및 시뮬레이션",
      ai_p2_gpuDataList_2_detail_5: "분자동역학",
      ai_p2_gpuDataList_2_detail_6: "유전체 염기 서열 분석",
      ai_p2_header_2: "AI 서버 & 워크스테이션",
      ai_p2_aiDataList_1_case: "고성능 그래픽/VDI/모델링",
      ai_p2_aiDataList_1_detail_1: "디지털 트윈 및 3D 세계/메타버스",
      ai_p2_aiDataList_1_detail_2: "고성능 그래픽",
      ai_p2_aiDataList_1_detail_3: "가상화",
      ai_p2_aiDataList_2_case: "메인스트림 AI",
      ai_p2_aiDataList_2_detail_1: "분석",
      ai_p2_aiDataList_2_detail_2: "GPU 데이터베이스 가속화",
      ai_p2_aiDataList_2_detail_3: "AI/ML 훈련 및 추론",
      ai_p2_aiDataList_2_detail_4: "경량 AI 훈련",
      ai_p2_aiDataList_3_case: "VDI 및 가상화",
      ai_p2_aiDataList_3_detail_1: "파워 유저를 위한 풍부한 협업",
      ai_p2_aiDataList_3_detail_2: "지식 근로자를 위한 VDI",
      ai_p2_aiDataList_4_case: "메인스트림 그래픽 및 VDI",
      ai_p2_aiDataList_4_detail_1: "그래픽 렌더링",
      ai_p2_aiDataList_5_case: "워크스테이션",
      ai_p2_aiDataList_5_detail_1: "영상분석",
      ai_p2_aiDataList_5_detail_2: "AI 모델 검증",
      ai_p2_aiDataList_5_detail_3: "AI 개발 / 테스트",
      ai_p2_aiDataList_6_case: "추론/엣지/VDI",
      ai_p2_aiDataList_6_detail_1: "엣지 추론",
      ai_p2_aiDataList_6_detail_2: "AI 모델 검증",
      ai_p2_aiDataList_6_detail_3: "AI 개발 / 테스트",
      solarDescription:
        "글로벌 리딩 제조사의 공식 파트너사로 태양광모듈 및 인버터 공급",
      solarDescription1:
        "에스씨지솔루션즈는 신재생에너지 사업분야로 확대해 나가고 있습니다.",
      solar_1_title: "모듈",
      solar_2_title: "인버터",
      solar_3_title: "태양광 발전 사업",
      solar_3_con_1_1: "설치부지 및 사업발굴",
      solar_3_con_1_2: "설치용량 배치검토",
      solar_3_con_1_3: "기초정보 입수 및 검토",
      solar_3_con_1_4: "인허가 가능여부 검토",
      solar_3_con_1_5: "수익성 및 사업 타당성 검토",
      solar_3_con_2_1: "발전사업허가 신청",
      solar_3_con_2_2: "사업자등록",
      solar_3_con_2_3: "한전 PPA 접수",
      solar_3_con_2_4: "개발행위허가 신청",
      solar_3_con_2_5: "공작물 축조신고 신청",
      solar_3_con_2_6: "공사계획신고 신청",
      solar_3_con_3_1: "공사계획신고",
      solar_3_con_3_2: "토목공사",
      solar_3_con_3_3: "자재입고",
      solar_3_con_3_4: "구조물 및 시설물 설치공사",
      solar_3_con_3_5: "전기공사",
      solar_3_con_3_6: "부대시설공사",
      solar_3_con_4_1: "시운전 및 사용전 검사",
      solar_3_con_4_2: "공사준공",
      solar_3_con_4_3: "한전 PPA 계약",
      solar_3_con_4_4: "RPS 설비등록",
      solar_3_con_4_5: "상업운전 시작",
      solar_3_con_5_1: "발전소 운영",
      solar_3_con_5_2: "전력판매 및 REC 판매",
      solar_3_con_5_3: "발전현황 모니터링",
      solar_3_con_5_4: "설비 유지보수",
      solar1way: "단면",
      solar2way: "양면",
      buildingDescription:
        "건물관리·부동산에 대한 종합서비스 제공 및 전기차충전기 시공서비스 수행",
      buildingDescription_1: "",
      building_1_title: "주요 사업",
      building_1_subject: "One Stop Total Service",
      building_1_des:
        "건물관리에 있어 최적의 상태를 유지하며 건물관리와 부동산에 대한 종합적인 서비스를 제공합니다.",
      building_1_con_1_title: "부동산자산관리",
      building_1_con_1_1: "임대·임차 서비스",
      building_1_con_1_2: "부동산 매입매각",
      building_1_con_1_3: "부동산 투자자문",
      building_1_con_1_4: "운영·임대·빌딩 관리 보고서",
      building_1_con_1_5: "총무/사무지원",
      building_1_con_2_title: "시설관리",
      building_1_con_2_1: "빌딩운영 및 유지관리",
      building_1_con_2_2: "기계, 자동제어, 소방설비 등 운영",
      building_1_con_2_3: "보일러, 냉동기 등 관리 감독",
      building_1_con_2_4: "시설장비이력 관리 및 외주 작업관리",
      building_1_con_2_5: "전기설비 및 승강기 유지감독 및 관리",
      building_1_con_2_6: "시설유지보수 업무",
      building_1_con_2_7: "영선·조경 관리업무",
      building_1_con_3_title: "보안관리",
      building_1_con_3_1: "취약지역 및 중요지역 경비",
      building_1_con_3_2: "건물 내 질서와 방범, 보안유지",
      building_1_con_3_3: "긴급사태 발생대비 활동",
      building_1_con_3_4: "입주자 건물 이용안내",
      building_1_con_3_5: "출입 인원·차량의 통제·관리",
      building_1_con_4_title: "미화관리",
      building_1_con_4_1: "건물 및 구조물의 유지보존",
      building_1_con_4_2: "쾌적한 근무환경 조성",
      building_1_con_4_3: "클리닝, 방역·방충 작업",
      building_1_con_4_4: "특수청소관리(카펫 및 대리석)",
      building_1_con_4_5: "실내·외 미화관리",
      building_1_con_5_title: "전기차충전기 시공관리",
      building_1_con_5_1: "전기차 충전시설 시공공사 및 유지보수",
      building_1_con_5_2: "충전기 교체 정비",
      building_1_con_5_3: "충전시설 콜센터 운영",
      building_2_title: "실적 현황",
      buildingLocation: "소재지",
      buildingArea: "면적",
      buildingOtherDetails: "기타",
      ev_title: "전기차 충전기 시공관리",
      ev_sub_title: "EV Charger Construction",
      ev_description:
        "전문적인 기술력을 바탕으로 완벽한 시공서비스를 제공합니다.",
      ev_process_title: "시공절차",
      csButton_1: "견적문의",
      csDescription:
        "도시가스 관련 공사·자원·자재 공급사업 추진 및 고객 민원상담을 위한 전문 고객센터 운영",
      csDescription_1: "",
      cs_1_title: "공사관리",
      cs_1_des:
        "안전하고 편리하게 사용할 수 있도록 최선을 다해 시공해드리고 있습니다.",
      cs_1_subDes_1:
        "투자공사 및 유지 정비 보수 : 배관매설 본, 공급관 공사 정압기 설치 공사 공급관 이설 공사 등",
      cs_1_con_title: "작업 공정",
      cs_2_title: "자원관리",
      cs_2_des:
        "자재공급, 가스미터 시험실 운영, 조정기 점검, 대용량 계량기 교체 등 업무를 수행하고 있습니다.",
      cs_2_subDes_1:
        "자재 구매, 발주, 입고관리 조정기 외 제작 판매 검사 및 부적합품 관리 등",
      cs_2_subDes_2:
        "가정용, 산업용 월별 샘플검사 시설전 연차별 검사 폐전계량기 검사 등",
      cs_2_subDes_3:
        "조정기 점검 및 대용량계량기 교체 : 중간압 지역 점검 및 필터 교체 계량기 10등급 ~ 4,000등급 노후 교체 민원교체 등",
      cs_3_title: "고객센터",
      cs_3_des:
        "지역 내에서 안전업무와 고객관리 업무를 총괄적으로 담당하고 있습니다.",
      cs_3_subDes_1:
        "가스사고 예방 조치, 도시가스 시설 설치·수리, 안전관리 홍보, 정기 안전점검, 도시가스 시설 공급 전 안전점검",
      cs_3_subDes_2:
        "검침·고지서 송달, 전·출입, 주택용·영업용·공급신청·중지, 설치·수리요금 체납관리, 고객 불만·요구사항 접수·조치 등 고객관리 업무",
      cs_4_title: "콜센터",
      cs_4_des:
        "도시가스 업무에 특화된 최상의 전문상담 및 업무 표준화를 제공하고 있습니다.",
      cs_4_subDes_1:
        "인/아웃 바운드 운영, 도시가스 미납관리, 리서치 및 모니터링, 교육사업",
      cs_4_con_2_title: "운영현황",
      cs_4_con_2_1_title: "서울도시가스 콜센터",
      cs_4_con_2_2_title: "인천도시가스 콜센터",
      cs_4_con_2_3_title: "JB 콜센터",
      cs_4_con_2_1_address:
        "경기도 고양시 일산서구 경의로 797, 서울도시가스 경기지사 4층",
      cs_4_con_2_2_address:
        "인천광역시 부평구 부평대로88, 부평대로 우체국 12층",
      cs_4_con_2_3_address: "충청남도 천안시 서북구 쌍용대로119, 4층",
      cs_4_con_3_title: "주요사업장",
      cs_4_con_3_open: "영업시간",
      cs_4_con_3_1_name: "서부1",
      cs_4_con_3_1_address:
        "서울시 강서구 방화동로85(방화동), 강서농협 방화지점 2층",
      cs_4_con_3_2_name: "서부5",
      cs_4_con_3_2_address:
        "서울시 영등포구 가마산로89길 4(신길동), 혜동빌딩 2층",
      cs_4_con_3_3_name: "남부5",
      cs_4_con_3_3_address: "서울시 관악구 남부순환로1767(봉천동), 2층",
      cs_4_con_3_4_name: "경기1",
      cs_4_con_3_4_address: "경기도 파주시 새꽃로94(금촌동), 2층",
      cs_4_con_3_5_name: "경기5",
      cs_4_con_3_5_address: "경기도 김포시 돌문로 95(사우동), 4층",
      cs_4_con_3_6_name: "경기6",
      cs_4_con_3_6_address:
        "경기도 고양시 덕양구 무원로6번길 61(행신동), GL프라자 203호",
      newsAnnouncement: "전자공고",
      newsNumber: "번호",
      newsTitle: "제목",
      newsDate: "작성일",
      newsPost: "게시물",
      recruitInfo: "채용정보",
      recruitTitle:
        "에스씨지솔루션즈와 함께 꿈을 펼치고 성장해 나갈 열정적인 여러분을 기다립니다!",
      recruitProgress: "전형절차",
      recruitProgress_1: "서류 심사",
      recruitProgress_2: "실무관 면접",
      recruitProgress_3: "경영진 면접",
      recruitProgress_4: "최종 합격",
      recruitClass: "구분",
      recruitType: "채용 유형",
      recruitschedule: "채용 일정",
      recruitPreferential: "우대 사항",
      recruitTypeValue: "인턴 / 신입 / 경력",
      recruitscheduleValue: "정기 공채 / 수시 채용",
      recruitPreferentialValue: "직무와 관련한 학위 / 경험 / 자격 소지",
      recruitApply: "지원 방법",
      recruitApply_1: "E-mail 입사지원",
      recruitApply_2: "온라인 입사지원",
      recruitApply_3: "구직사이트 공고 지원",
      recruitApplyDownload: "입사 지원서",
      benefits: "복리후생",
      benefitsDescripion:
        "에스씨지솔루션즈는 임직원 여러분의 복리후생 증진을 위해 노력합니다.",
      benefits_con1: "성과급 제도",
      benefits_con1_value: "조직,개인 성과평가에",
      benefits_con1_value_2: "따라 보상",
      benefits_con2: "포상 제도",
      benefits_con2_value: "정기 우수사원 선발",
      benefits_con3: "교육 지원",
      benefits_con3_value: "(업무 유관자)",
      benefits_con4: "통신비 지원",
      benefits_con4_value: "(업무직군·직책 기반)",
      benefits_con5: "상해보험",
      benefits_con5_value: "전 직원 상해보험 가입",
      benefits_con5_value_2: "사고대비/일상생활 보상",
      benefits_con6: "건강검진",
      benefits_con6_2: " ",
      benefits_con6_value: "매년 종합검진 제공",
      benefits_con6_value_2: "가족검진 할인",
      benefits_con7: "휴양시설",
      benefits_con7_value: "전국 회원권 리조트,",
      benefits_con7_value_2: "연수원 숙박지원",
      benefits_con8: "기념 선물",
      benefits_con8_value: "명절/출산",
      benefits_con9: "경조사 지원",
      benefits_con9_value: "경조금/화환",
      benefits_con9_value_2: "휴가/상조물품",
      benefits_con10: "휴가/휴식",
      benefits_con10_2: " ",
      benefits_con10_value: "연차/반차/반반차 제도",
      benefits_con10_value_2: "휴게실 간식제공",
      recruitPosts: "채용공고",
      recruitTerm: "기간",
      recruitNewcomer: "신입",
      recruitCareer: "경력",
      recruitNewCareer: "신입/경력",
      recruitIntern: "인턴",
      recruitPost: "게시물",
      recruitStatus: "상태",
      recruitStatus_1: "채용전",
      recruitStatus_2: "채용중",
      recruitStatus_3: "채용 완료",
      supportClass: "사업구분",
      supportClass2: "대상 제품",
      supportInquiry: "문의하기",
      supportCon: "종합관리 및 도시가스 고객지원사업",
      modalTitle: "견적 문의",
      modalTitle2: "문의",
      modalType: "종류",
      modalType2: "구분",
      modalOther: "기타 문의",
      modalLabel: "제보 / 건의",
      modalProductType: "제품 종류",
      modalInquiryType: "문의 유형",
      modalCompany: "업체명",
      modalManager: "담당자",
      modalEmail: "이메일",
      modalName: "이름",
      modalContact: "연락처",
      modalContents: "내용",
      modalDell_1: "서버",
      modalDell_2: "스토리지",
      modalDell_3: "솔루션",
      modalDell_4: "네트워크",
      modalSeagate_1: "외장하드",
      modalBenq_1: "전자칠판",
      modalArista_1: "제품 문의",
      modalService_1: "서비스 문의",
      modalInformation_1: "제보",
      modalSuggestion_1: "건의",
      modalCancle: "취소",
      modalSend: "확인",
      modalSend2: "등록",
    },
  },
  en: {
    translation: {
      notFound: "Not found, try access in normal route.",
      back: "back",
      home: "home",
      introduce: "Company",
      partner: "Affiliates",
      business: "Business",
      news: "PR/IR",
      recruit: "Recruit",
      cs: "Customer Support",
      main01: "Customer Value is",
      main01_1: "SCG Solutions Top Priority",
      main02: "No1. Solution Provider",
      main03: "We succeed together",
      main03_1: "with our business partners",
      main04: "End to End Solution Provider",
      mainIt: "IT",
      mainSolar: "Renewable Energy",
      mainManage: "Total Management",
      mainManage2_1: "Total Management",
      mainManage2_2: "City Gas Customer Support",
      mainCs: "City Gas Customer Support",
      mainItBusiness: "IT",
      mainSolarBusiness: "Renewable Energy",
      mainManageBusiness: "Total Management",
      mainCsBusiness: "City Gas Customer Support",
      solution: "Solution",
      cloud: "Cloud",
      ito: "ITO",
      solar: "Solar PV",
      buildingManag: "Total Building Management",
      gasCs: "City Gas Customer Support",
      more: "More",
      footerLogo: "SCG Solutions Co., Ltd",
      footerPolicy1: "Ethical Management",
      footerPolicy2: "Privacy Policy",
      policyTitle: "Privacy Policy",
      footerSiteMap: "Site Map",
      footerCs: "Customer Support",
      footerPost: "VOE",
      footerDownload: "Reference Room",
      footerAddressKey: "Address",
      footerAddressValue:
        "5th floor, Whanin Building, 11, Beobwon-ro 6-gil, Songpa-gu, Seoul",
      footerCsCenterKey: "Customer Support",
      footerCsCenterValue: "02-827-0020",
      footerEmailKey: "E-Mail",
      footerEmailValue: "admin@scgs.co.kr",
      footerTelKey: "Tel",
      footerTelValue: "1811-7247",
      footerCopyrightKey: "Copyright by",
      footerCopyrightValue: "SCG Solutions Co., Ltd. All Rights Reserved.",
      footerBusinessRegistrationKey: "Business registration number",
      footerBusinessRegistrationValue: "109-86-20739",
      footerCeoKey: "CEO",
      footerCeoValue: "Seongneung Nam",
      introMain01: "Customer Value is",
      introMain01_1: "SCG Solutions Top Priority",
      introMain02:
        "IT specialized company of Seoul City Gas Group, SCG Solutions", // 분할
      introMain02_1: "We are strategically securing global No. 1 Solutions", // 분할
      introMain02_2:
        "and continue to expand our business through the establishment of domestic and overseas corporations and investments.", // 분할
      introInfoScale: "Revenue",
      introInfoScaleValue: "$224M",
      introInfoWorker: "Employees",
      introInfoWorkerValue: "628",
      introInfoPartner: "Partners",
      introInfoPartnerValue: "1,484",
      introInfoCompanyName: "Profile",
      introInfoCompanyNameValue: "SCG Solutions",
      introInfoFoundingDate: "Established",
      introInfoFoundingDateValue: "Nov, 2009",
      introInfoComment: "*Dec 23 ",
      introInfoBusiness: "Business Area",
      introInfoBusinessValue1: "IT",
      introInfoBusinessValue2: "Renewable Energy",
      introInfoBusinessValue3: "Total Management",
      introInfoBusinessValue4: "City Gas Customer Support",
      introHistory: "History",
      introHistoryterm1: "2016 ~ Now",
      introHistoryterm2: "2009 ~ 2015",
      introHistoryterm3: "Present",
      introHistorySlogan1: "Expand Business",
      introHistorySlogan1_0: "Build Infrastructure for Growth",
      introHistorySlogan1_1: "Secure Global No.1 Solutions",
      introHistorySlogan1_2: "Enhance Sales infrastructure",
      introHistorySlogan2: "Build the Business Fundamental",
      introHistorySlogan2_0: "Establish the Overseas Branches",
      introHistory23_1: "Dell Consumer Distributor",
      introHistory23_2: "TP-Link Distributor",
      introHistory23_3: "Seagate Retail HDD Distributor",
      introHistory22_1:
        "Establishment of SCG Grid (Daehan GM Corp. acquisition)",
      introHistory22_2: "Establishment of Daejeon Branch",
      introHistory22_3: "BenQ Distributor",
      introHistory22_4: "Arista Networks Distributor",
      introHistory22_5: "Salesforce NAFF CS Consultation Talk Development",
      introHistory22_6:
        "Dell Technologies Distributor of the Year Service Sales FY22 Awards",
      introHistory20_1: "Establishment of SFLO",
      introHistory20_2: "Acer Partnership",
      introHistory20_3: "JinKo, JA Solar Partnership",
      introHistory19_1: "Establishment of Busan Branch",
      introHistory19_2: "Seagate Distributor",
      introHistory19_3: "Risen Energy Distributor",
      introHistory19_4:
        "Salesforce Multi-Channel Message Service(NAFF) Development",
      introHistory19_5: "2020 Heroes Awards",
      introHistory19_6: "Dell Technologies Best Distributor Awards",
      introHistory19_7: "Dell Technologies Shining Star Awards",
      introHistory19_8: "Dell Technologies Storage Growth Awards",
      introHistory18_1: "SNN Merger",
      introHistory18_2: "Salesforce Partnership",
      introHistory18_3: "Targus Distributor",
      introHistory18_4: "Dell EMC Excellence in Digital Marketing Awards",
      introHistory17_1: "Dell EMC Distributor",
      introHistory17_2: "Dell EMC Best Achievements Partner Awards",
      introHistory16_1: "Technicolor Distributor",
      introHistory16_2: "Dell EUC Distributor",
      introHistory16_3: "Dell Top Commercial Distributor Awards",
      introHistory15_1: "Establishment of SCGS Dalian",
      introHistory15_2: "Dell Top Distributor Awards",
      introHistory14_1: "Dell Distributor",
      introHistory14_2: "Huawei Distributor",
      introHistory13_1: "Establishment of SCGS Indonesia",
      introHistory13_2: "Acronis Distributor",
      introHistory13_3: "Cisco SI Gold Partnership",
      introHistory12_1: "IBM Partnership",
      introHistory10_1: "Establishment of SCGS Thailand",
      introHistory10_2: "Qlogic Distributor",
      introHistory09_1: "SCG Solutions Established",
      introContact: "Location",
      introPhoneNumber: "Contact",
      introFax: "Fax",
      introLocation_1: "Head office",
      introLocation_2: "Yongsan Br.",
      introLocation_3: "Gyeonggi Br.",
      introLocation_4: "Busan Br.",
      introLocation_5: "Daejeon Br.",
      introLocation_6: "Logistics Ctr.",
      introAddress_1:
        "SCG Solutions Co., Ltd., 11, Beobwon-ro 6-gil, Songpa-gu, Seoul",
      introAddress_2:
        "4F, Yongsan Building, 273 Hangang-daero, Yongsan-gu, Seoul",
      introAddress_3:
        "4F, 797 Gyeongui-ro, Ilsanseo-gu, Goyang-si, Gyeonggi-do",
      introAddress_4: "22F S05, Centumseo-ro 30 KNN Tower, Haeundae-gu, Busan",
      introAddress_5:
        "18F(1806), 136, Daejeon Trade Center, Seo-gu Office Building, Daejeon City",
      introAddress_6:
        "2F and 3F, Taekyung Building, 123-10, Wonhyo-ro, Yongsan-gu, Seoul",
      partnerTitle: "Affiliates",
      partnerSubTitle1: "Domestics",
      partnerLinkHomePage: "Home page",
      partnerBusiness: "Business",
      partnerLocation: "Location",
      partnerSubTitle2: "Overseas",
      partnerKoDescription_1:
        "Seoul City Gas is the first city gas supplier in Korea to supply gas upto 1 million tons and 1 million households, and is a key player of an energy comprehensive company, expanding its business to overseas resource development projects.",
      partnerKoDescription_2:
        "SCG Grid is the No. 1 industrial gas meter company (turbine and rotary) and is a meter manufacturer that develops and produces products related to domestic and industrial gas meters incorporating IoT technology.",
      partnerKoDescription_3:
        "SCGLab is a platform-specialized company that provides gas apps for city gas integrated solutions with the highest customer utilization and satisfaction among domestic public service apps to major city gas companies in Korea.",
      partnerKoDescription_4:
        "Seoul CNG is an eco-friendly energy company that is expanding its energy business to hydrogen and solar power. Additionally, it operates a natural gas charging station, installed the first commercial charging station in Korea, and operates five charging stations and twelve self-employed charging stations in Seoul and Gyeonggi area.",
      partnerKoDescription_5:
        "Hometelier provides services that support lifestyle such as home care, office cleaning, and concierge from signle to multi-person households.",
      partnerKoCategory_1:
        "Urban Gas Supply and Gas Supply Equipment Corporation",
      partnerKoCategory_2:
        "Manufacture and Sale of Domestic and Industrial Gas meter related products",
      partnerKoCategory_3:
        "IT Service Development and Digital Content Business",
      partnerKoCategory_4: "Natural Gas Supply for Transportation Equipment",
      partnerKoCategory_5: "Lifestyle Support Services",
      partnerKoArea_1:
        "607, Gonghang-daero, Gangseo-gu, Seoul (Yeomchang-dong, Seoul City Gas Company)",
      partnerKoArea_2: "717-7 Gojan-dong, Namdong-gu, Incheon",
      partnerKoArea_3:
        "6F, Inobase building, Worldcupbuk-ro 6-gil, Mapo-gu, Seoul",
      partnerKoArea_4: "125, Gayang-daero, Mapo-gu, Seoul (Sangam-dong)",
      partnerKoArea_5:
        "101-205 Tree Square, 40, Baekbeom-ro 99-gil, Yongsan-gu, Seoul",
      partnerGlobalDescription_1:
        "SCGS Thailand is expanding its business area focusing on IT Solution business, ITO/SI business, and solar panel supply business.",
      partnerGlobalDescription_2:
        "SCGS Dalian is promoting a telesales marketing business based on Call Outbound.",
      partnerGlobalDescription_3:
        "Promotes oil industry development in the U.S.",
      partnerGlobalCategory_1: "IT Solution, ITO/SI, Solar PV Supply",
      partnerGlobalCategory_2: "Telesales Marketing/Sales",
      partnerGlobalCategory_3: "IT Solution, ITO/SI, Solar PV Supply",
      partnerGlobalArea_1:
        "6/10 Soi Patanasin, Pipatanasin Building, 10th Floor, Narathiwas Ratchanakharin Road, Thungmahamek, Sathorn, Bangkok 10120",
      partnerGlobalArea_2:
        "602, Shenghui B/D, No.9 Gaoxin street High-tech park, Dalian City, Liaoning Province, CH",
      partnerGlobalArea_3:
        "4925 Greenville Ave., Ste 709, Dallas, Texas 75206, USA",
      businessDescription_1:
        "SCGS is the Dell Technologies' most reliable Distributor. Enhance your Capabilities with Dell Technologies' Solutions with SCGS.",
      businessDescription_2:
        "Seagate is the global leader in Data Storage Solutions and SCGS provides all the Seagate Products such as Storage, HDD, SSD and more.",
      businessDescription_3:
        "Arista Networks is the global leader in Data Center Networking Solutions. SCGS, as an official distributor of Arista Networks in Korea, provides all Arista Networks' products and solutions.",
      businessDescription_4:
        "BenQ is the global leader in Display and Electronic Board Solutions. SCGS provides Electronic Board and more.",
      dellDescription:
        "Enhance your capabilities with Dell Technologies' comprehensive solutions. SCGS is Dell Technologies' reliable Distributor, provides every essential solutions.",
      dellDescription1:
        "The Global leader of Server, Storage & Network Solution, Dell Technologies",
      dellDescription2:
        "SCGS provides Competitive Products and Solutions as the No. 1 Distributor of Dell Technologies.",
      dellButton_1: "Dell Quotation",
      dellButton_2: "Dell Premier",
      dell_1_des:
        "Business Innovation, Technology and Solution for Adaptation and Growth",
      dell_1_subDes:
        "Engine for Business Innovation, New PowerEdge Server Portfolio",
      dell_1_con_1: "Tower Server",
      dell_1_con_1_des: "Designed for modern office environment",
      dell_1_con_2: "Rack Server",
      dell_1_con_2_des: "Deliver secure, scalable infrastructure",
      dell_1_con_3: "Modular Server",
      dell_1_con_3_des: "Maximum lifecycle value of IT investment",
      dell_2_des:
        "Dell Technologies' software-based storage solution automizes IT and provides ensured innovation by securing multi-cloud control and mobility.",
      dell_2_subDes: "Storage Solution based on Powerful Software",
      dell_2_con_1: "Dell Technologies Back-up",
      dell_2_con_1_des:
        "Powerful Architecture, Easy Operation, Reliable Innovation",
      dell_2_con_2: "Dell Technologies Network",
      dell_2_con_2_des:
        "Easily monitor in every business size, Intellectual Insight by processing all data no matter where",
      dell_2_con_3: "Dell Technologies Hyper Converged Infrastructure",
      dell_2_con_3_des: "Geographically dispersed Cloud-scale Object storage",
      dell_2_con_4: "Dell Technologies Back-up",
      dell_2_con_4_des:
        "Powerful Architecture, Easy Operation, Reliable Innovation",
      dell_2_con_5: "Dell Technologies Network",
      dell_2_con_5_des:
        "Easily monitor in every business size, Intellectual Insight by processing all data no matter where",
      dell_2_con_6: "Dell Technologies Hyper Converged Infrastructure",
      dell_2_con_6_des: "Geographically dispersed Cloud-scale Object storage",
      dell_3_subDes:
        "SCGS, Dell Technologies' No. 1 Distributor, is End-to-End Solution Provider of Dell Technologies' all product.",
      dell_3_con_1: "Display",
      dell_3_con_1_des: "Contents",
      dell_3_con_2: "Desktop",
      dell_3_con_2_des: "Contents",
      dell_3_con_3: "Workstation",
      dell_3_con_3_des: "Contents",
      dell_3_con_4: "Notebook",
      dell_3_con_4_des: "Contents",
      dell_3_con_5: "Tower/Rack Workstation",
      dell_3_con_5_des: "Contents",
      dell_3_con_6: "Accessories",
      dell_3_con_6_des: "Contents",
      seagateDescription:
        "SCG Solutions with, Seagate SCGS is an official distributor of Seagate, the global leader in data storage solution industry, provides Seagate HDDs, SSDs, and more.",
      seagateDescription1:
        "The Global leader in data storage solution industry, Seagate",
      seagateDescription2:
        "SCGS provides Storage, HDD, SSD and Other competitive products as The Reliable Distributor of Seagate.",
      seagateButton_1: "Seagate Storage Inquiry",
      seagateButton_2: "Seagate Purchase",
      seagateButton_3: "CCTV Security",
      seagate_1_des:
        "Official distributor of Seagate, providing Seagate HDDs, SSDs, and more",
      seagate_1_subDes:
        "Always-running function, Desinged to secure Stability and Reliability.",
      seagate_1_con_1: "BarraCuda",
      seagate_1_con_1_des: " ",
      seagate_1_con_2: "FireCuda",
      seagate_1_con_2_des: " ",
      seagate_1_con_3: "SkyHawk",
      seagate_1_con_3_des: " ",
      seagate_1_con_4: "IronWolf HDD",
      seagate_1_con_4_des: " ",
      seagate_1_con_5: "Exos",
      seagate_1_con_5_des: " ",
      seagate_1_con_6: "Nytro",
      seagate_1_con_6_des: " ",
      seagate_2_title: "Hard Disk",
      seagate_2_des: "Unique Design, Compact, Small, and Light mobile SSD",
      seagate_2_subDes:
        "Mobile Disk easy to carry and provides enough memory to save a great deal of contents",
      seagate_2_con_1: "Fast One Touch SSD",
      seagate_2_con_1_des:
        "Unique Design, Compact, Small, and Light mobile SSD",
      seagate_2_con_2: "One Touch HDD",
      seagate_2_con_2_des:
        "Mobile Disk easy to carry and provides enough memory to save a great deal of contents",
      seagate_2_con_3: "One Touch Desktop Hub",
      seagate_2_con_3_des:
        "Desktop Hard Disk in diverse memory upto 20TB, able to exchange with multiple platforms",
      seagate_2_con_4: "Rugged SSD USB-C",
      seagate_2_con_4_des:
        "SSD/ HDD with extreme security system for creation experties",
      seagate_3_des: " ",
      seagate_3_subDes: " ",
      seagate_3_con_1: "CORVAULT",
      seagate_3_con_1_des: " ",
      seagate_3_con_2: "All-Flash Disk Arrays",
      seagate_3_con_2_des: " ",
      seagate_3_con_3: "Converged Platforms",
      seagate_3_con_3_des: " ",
      seagate_3_con_4: "Expansion and JBOD",
      seagate_3_con_4_des: " ",
      benqDescription:
        "BenQ is the global leader in Display and Electronic Board Solutions. SCGS provides Electronic Board and more.",
      benqDescription1:
        "The Global Gaming Display and Electronic Board Brand, BenQ",
      benqDescription2:
        "SCGS provides The Electronic Board and related products as The Official Distributor of BenQ.",
      benqButton_1: "BenQ Quotation",
      benq_1_title: "Electronic Board",
      benq_1_des: " ",
      benq_1_subDes: " ",
      benq_1_con_1: " Enterprise",
      benq_1_con_1_des: " ",
      benq_1_con_2: "Education",
      benq_1_con_2_des: " ",
      benq_1_con_3: "Related Products",
      aristaDescription:
        "The global leader in Data Center Networking Solutions, Arista Networks SCGS, as an official distributor of Arista Networks in Korea, provides all Arista Networks' products and solutions.",
      aristaDescription1:
        "The global leader in Data Center Networking Solutions Arista Networks",
      aristaDescription2:
        "SCGS provides all Products and Solutions, as The Official Distributor of Arista Networks",
      aristaButton_1: "ARISTA Quotation",
      arista_1_title: "Line-Up",
      arista_1_des: " ",
      arista_1_subDes: "Arista ",
      arista_1_brochure: "Brochure",
      arista_1_con_1: "Hyperscale Data center",
      arista_1_con_1_des: "설명",
      arista_1_con_2: "Universal Cloud Networking",
      arista_1_con_2_des: "설명",
      arista_1_con_3: "Cognitive Campus",
      arista_1_con_3_des: "설명",
      arista_1_con_4: "DEEP Buffer ",
      arista_1_con_4_des: "설명",
      arista_1_con_5: "Programmable",
      arista_1_con_5_des: "설명",
      arista_1_con_6: "CloudVision",
      arista_1_con_6_des: "설명",
      tplinkDescription: "설명",
      tplinkDescription1:
        "The global leader in Wi-Fi product market share, TP-Link",
      tplinkDescription2:
        "SCGS provides all Home & Business network equipments, as The Official Distributor of tp-link.",
      tplink_1_title: "Omada Network",
      tplink_1_con_1: "EAP610-Outdoor",
      tplink_1_con_2: "EAP670",
      tplink_1_con_3: "ER8411",
      tplink_1_con_1_des: "설명",
      tplink_1_con_2_des: "설명",
      tplink_1_con_3_des: "설명",
      tplink_2_title: "CCTV Security",
      tplink_2_con_1: "Tapo C220",
      tplink_2_con_2: "Tapo C110",
      tplink_2_con_3: "Tapo C425",
      tplink_2_con_4: "VIGI C540V",
      tplink_2_con_5: "VIGI NVR1104H-4P",
      tplink_2_con_6: "VIGI NVR2016H",
      tplink_2_con_1_des: "설명",
      tplink_2_con_2_des: "설명",
      tplink_2_con_3_des: "설명",
      tplink_2_con_4_des: "설명",
      tplink_2_con_5_des: "설명",
      tplink_2_con_6_des: "설명",
      cloudDescription:
        "Get the Direction of Sales with Salesforce Solution Data.",
      cloudDescription_1: "The Global No.1 SaaS-based CRM Solution, Salesforce",
      cloudDescription_2:
        "SCGS is The Consulting partner of Salesforce, making business successful by connecting more closely with customers.",
      cloud_1_con_1:
        "Establish business processes and enhance the capabilities of the sales team.",
      cloud_1_con_1_des:
        "Can integrate and interconnect all the company's sales activities based on 360-degree customer view, pipeline, and a single platform,",
      cloud_1_con_2_des:
        "allowing for the standardization and automation of sales processes.",
      cloud_2_con_1:
        "Integrated customer service management for various channels and knowledge management for customer response.",
      cloud_2_con_1_des:
        "Customer Service Platform that actualizes continuous communication in every channel",
      cloud_3_con_1: "Communicate with customer 1 on 1 in new ways.",
      cloud_3_con_1_des:
        "Personalized Marketing Activities on real-time response through MMS, E-Mail, and SNS",
      naffDescription:
        "SCGS' NAFF is multi-channel messaging service that can work with real-time customer's information, not just simple MMS.",
      naffDescription_1:
        "Salesforce Add-On Package which is Developed by SCGS, NAFF",
      naffDescription_2:
        "SCGS is a consulting partner of Salesforce, making business successful by connecting more closely with customers.",
      naff_1_title: "Multi-Channel Messaging Package",
      naff_1_con_1:
        "Multi-Channel Messaging Service that can work with real-time customer's information.",
      naff_1_con_1_des:
        "Integrated with Kakao Talk, improve marketing efficiency.",
      naff_2_title: "Customer Support Package(based on Kakaotalk)",
      naff_2_con_1:
        "Package Service that integrates Core Cloud and Counselling talk to improve CS efficiency.",
      naff_2_con_1_des:
        "Conveniently view real-time consultations with customers and results with reports and dashboards",
      itoDescription:
        "SI, SM and IT Outsourcing business specialized in Energy company",
      ito_1_title: "ITO",
      ito_1_sub_1_list_1: "System Maintenance",
      ito_1_sub_1_list_2: "System Integration",
      ito_1_sub_1_list_3: "IT Infra Construction",
      ito_1_sub_1_list_4: "ITO and Consulting",
      ito_1_sub_2_title_1: "Main Projects",
      ito_1_sub_2_list_1: "AMI meters, MDMS-system construction",
      ito_1_sub_2_list_2:
        "Advancement of customer service center mobile system",
      ito_1_sub_2_list_3: "Integrated-information system Advancement",
      ito_1_sub_2_list_4: "CNG POS System",
      ito_1_sub_2_list_5:
        "Advancement and Maintenance of Integrated Call center",
      ito_1_sub_2_list_6:
        "Integrated Maintenance of Seoul City Gas Information System",
      ito_2_title: "Clients",
      ito_2_con_1: "Seoul City Gas",
      ito_2_con_2: "SCGLAB",
      ito_2_con_3: "Seoul CNG Co.",
      ito_2_con_4: "JB Co.",
      ito_2_con_5: "Hometelier",
      ai_main_title_1:
        "We offer an AI Factory Starter kit that is optimized with",
      ai_main_title_2:
        "configuration of hardware, software and network for your business.",
      ai_header_2: "AI Recommended Model",
      ai_1_con_1_des: "Learning and inference at Medium & Large scales",
      ai_2_con_1_des: "Learning at a Small scales",
      ai_2_con_2_des: "Video Analytics & Learning",
      ai_p2_li_header_1: "Utilization",
      ai_p2_li_header_2: "Model & GPU",
      ai_p2_header_1: "GPU High-Performance application server",
      ai_p2_gpuDataList_1_case: "HPC/AI/ML/DL Generative AI",
      ai_p2_gpuDataList_1_detail_1: "Pathways Language Model",
      ai_p2_gpuDataList_1_detail_2: "Modeling & Simulation",
      ai_p2_gpuDataList_2_case: "HPC/AI/Database/Analysis",
      ai_p2_gpuDataList_2_detail_1: "AI/ML/DL Learning & Inference",
      ai_p2_gpuDataList_2_detail_2: "MLM(Middle Language Model)",
      ai_p2_gpuDataList_2_detail_3: "Recognizing images",
      ai_p2_gpuDataList_2_detail_4: "Modeling & Simulation",
      ai_p2_gpuDataList_2_detail_5: "Molecular dynamics",
      ai_p2_gpuDataList_2_detail_6: "Next-generation sequencing",
      ai_p2_header_2: "AI Server & Workstation",
      ai_p2_aiDataList_1_case: "High performance graphic/VDI/Modeling",
      ai_p2_aiDataList_1_detail_1: "Digital Twin / 3D / Metaverse",
      ai_p2_aiDataList_1_detail_2: "High performance graphic",
      ai_p2_aiDataList_1_detail_3: "Virtualization",
      ai_p2_aiDataList_2_case: "Mainstream AI",
      ai_p2_aiDataList_2_detail_1: "Analytics",
      ai_p2_aiDataList_2_detail_2: "Accelerating database using GPU",
      ai_p2_aiDataList_2_detail_3: "Learning and inference at AI/ML",
      ai_p2_aiDataList_2_detail_4: "sLLM(smaller Large Language Model)",
      ai_p2_aiDataList_3_case: "VDI & Virtualization",
      ai_p2_aiDataList_3_detail_1:
        "Deliver real workforce transformation with Virtual Desktop Infrastructure",
      ai_p2_aiDataList_3_detail_2: "Centralize and simplify IT management",
      ai_p2_aiDataList_4_case: "Mainstream Graphic & VDI",
      ai_p2_aiDataList_4_detail_1: "Graphic Rendering",
      ai_p2_aiDataList_5_case: "Workstation",
      ai_p2_aiDataList_5_detail_1: "Intelligent Video Analytics",
      ai_p2_aiDataList_5_detail_2: "AI Model Verification",
      ai_p2_aiDataList_5_detail_3: "AI Development & Testing",
      ai_p2_aiDataList_6_case: "Inference/Edge/VDI",
      ai_p2_aiDataList_6_detail_1: "Inference at the Edge",
      ai_p2_aiDataList_6_detail_2: "AI Model Verification",
      ai_p2_aiDataList_6_detail_3: "AI Development & Testing",
      solarDescription:
        "SCGS is The Official partner of global leading manufactures",
      solarDescription1:
        "SCGS is expanding The Business to the renewable energy sector.",
      solar_1_title: "Module",
      solar_2_title: "Inverter",
      solar_3_title: "Solar PV",
      solar_3_con_1_1: "Site selection and business development",
      solar_3_con_1_2: "Site assessments for installation suitability",
      solar_3_con_1_3: "Basic information (solar irradiation data) review",
      solar_3_con_1_4: "Licenses feasibility evaluation",
      solar_3_con_1_5: "Profitability and viability assessment",
      solar_3_con_2_1: "Application for power generation project permits",
      solar_3_con_2_2: "Business registration",
      solar_3_con_2_3: "KEPCO PPA submission",
      solar_3_con_2_4: "Application for development permits",
      solar_3_con_2_5:
        "Application for construction declaration for the power facility",
      solar_3_con_2_6: "Construction plans",
      solar_3_con_3_1: "Construction plans declaration",
      solar_3_con_3_2: "Civil engineering work",
      solar_3_con_3_3: "Material delivery",
      solar_3_con_3_4: "Structures and facilities installation",
      solar_3_con_3_5: "Electrical work",
      solar_3_con_3_6: "Ancillary facility construction",
      solar_3_con_4_1: "Commissioning and pre-operation inspection",
      solar_3_con_4_2: "Construction completion",
      solar_3_con_4_3: "PPA with KEPCO",
      solar_3_con_4_4: "RPS facility registration",
      solar_3_con_4_5: "Commercial operation commencement",
      solar_3_con_5_1: "Power plant operation",
      solar_3_con_5_2: "Electricity and REC sales",
      solar_3_con_5_3: "Monitoring of power generation status",
      solar_3_con_5_4: "Facility maintenance and upkeep",
      solar1way: "SS",
      solar2way: "DS",
      buildingDescription: "The Management of Properties and",
      buildingDescription_1:
        "The Construction of Electric Vehicle Charging Station",
      building_1_title: "Main Business",
      building_1_subject: "One Stop Total Service",
      building_1_des:
        "Maintain optimal conditions in building and provide comprehensive services for building management and real estate.",
      building_1_con_1_title: "Real Estate Asset Management",
      building_1_con_1_1: "Rental / lease service",
      building_1_con_1_2: "Purchase and sale real estate",
      building_1_con_1_3: "Investment consultation of real estate",
      building_1_con_1_4: "Operation / lease / building management report",
      building_1_con_1_5: "Support the general affair and office work",
      building_1_con_2_title: "Facility Management",
      building_1_con_2_1: "Building operation and maintenance",
      building_1_con_2_2:
        "Operation of machinery, automated control systems, fire protection equipment, and central monitoring control devices",
      building_1_con_2_3:
        "Supervision and management of boilers, refrigeration units",
      building_1_con_2_4:
        "Facility equipment history management and outsourcing work management",
      building_1_con_2_5:
        "Supervision and management of electrical facilities and elevators",
      building_1_con_2_6: "Facility maintenance tasks",
      building_1_con_2_7: "Management of waste disposal and landscape",
      building_1_con_3_title: "Security",
      building_1_con_3_1: "Security in vulnerable areas and key areas",
      building_1_con_3_2:
        "Maintaining order, crime prevention, and security within the building",
      building_1_con_3_3: "Emergency preparedness",
      building_1_con_3_4: "Providing building usage guidelines",
      building_1_con_3_5:
        "Access control and management of personnel and vehicles",
      building_1_con_4_title: "Beautification",
      building_1_con_4_1:
        "Preservation and maintenance of buildings and structures",
      building_1_con_4_2: "Creating comfortable working environment",
      building_1_con_4_3: "Cleaning, disinfection, and pest control",
      building_1_con_4_4:
        "Specialized cleaning management (carpets and marble)",
      building_1_con_4_5: "Indoor and outdoor beautification management",
      building_1_con_5_title: "EV charging stations installation / management",
      building_1_con_5_1:
        "Construction and maintenance of EV charging facilities",
      building_1_con_5_2: "Replacement and maintenance of charging stations",
      building_1_con_5_3: "Operation of a call center for charging facilities",
      building_2_title: "Performance Status",
      buildingLocation: "Location",
      buildingArea: "Area",
      buildingOtherDetails: "Etc",
      ev_title: "Construction and Management of EV Charging Station",
      ev_sub_title: "EV Charger Construction",
      ev_description:
        "Provide excellent Construction Services based on our professional expertise and technical skills.",
      ev_process_title: "Process",
      csButton_1: "Quotation",
      csDescription:
        "City Gas-related Construction, Resource and Material Supply and",
      csDescription_1:
        "Operation of specialized CS centers for customer counseling",
      cs_1_title: "Construction Management",
      cs_1_des:
        "Provide safe and convenient use of city gas by carrying out various constructions",
      cs_1_subDes_1:
        "Investment construction / Maintenance : Installation of pipelines, supply mains, pressure regulating stations",
      cs_1_con_title: "Process",
      cs_2_title: "Material Supply Management",
      cs_2_des:
        "Provide services such as material supply, operating a gas meter testing laboratory, regulator inspection, and replacement of large capacity meters.",
      cs_2_subDes_1:
        "Material procurement, Purchase order, Inventory management, manufacture and sale of regulators and other equipment, inspection and management of non-conforming products, etc",
      cs_2_subDes_2:
        "Monthly sample inspection for residential and industrial purposes periodic inspection of facilities inspection of decommissioned meters, etc",
      cs_2_subDes_3:
        "Inspection and filter replacement in medium-pressure areas, replacement of aged meters ranging from 10th grade to 4,000th grade or aged 20 years or older",
      cs_3_title: "Service Center",
      cs_3_des:
        "Generalize safety operations and customer management tasks within the area.",
      cs_3_subDes_1:
        "Measures to prevent gas accidents, regular safety inspections, Installation and repair of city gas facilities, pre-supply safety inspections of city gas facilities, distribution of promotional materials on safety management",
      cs_3_subDes_2:
        "Meter reading and delivery of utility bills ,handling customer entries and exits, receipt and resolution of customer complaints and requests, and processing applications for residential and commercial gas supply, and termination",
      cs_4_title: "Call Center",
      cs_4_des:
        "Provide specialized expert consultation and business standardization tailored to the city gas industry.",
      cs_4_subDes_1:
        "In&out bound operations, Management of unpaid city gas bills, Research and monitoring, Education",
      cs_4_con_2_title: "Operating status",
      cs_4_con_2_1_title: "Seoul City Gas Call center",
      cs_4_con_2_2_title: "Incheon City Gas Call center",
      cs_4_con_2_3_title: "JB Call center",
      cs_4_con_2_1_address:
        "4F, 797 Gyeongui-ro, Ilsanseo-gu, Goyang-si, Gyeonggi-do, Seoul City Gas Gyeonggi Branch.",
      cs_4_con_2_2_address:
        "12th floor of Bupyeong Post Office, 88 Bupyeong-daero, Bupyeong-gu, Incheon",
      cs_4_con_2_3_address:
        "4F, 119 Ssangyong-daero, Seobuk-gu, Cheonan-si, Chungcheongnam-do",
      cs_4_con_3_title: "Office",
      cs_4_con_3_open: "Business Hours",
      cs_4_con_3_1_name: "Seobu1",
      cs_4_con_3_1_address:
        "2F, Gangseo Nonghyup Banghwa Branch, 85 Banghwa-dongro, Gangseo-gu, Seoul",
      cs_4_con_3_2_name: "Seobu5",
      cs_4_con_3_2_address:
        "2F, Hyedong Building, 4 Gamasan-ro 89-gil, Singil-dong, Yeongdeungpo-gu, Seoul",
      cs_4_con_3_3_name: "Nambu5",
      cs_4_con_3_3_address:
        "2F, 1767 Nambusunhwan-ro, Bongcheon-dong, Gwanak-gu, Seoul",
      cs_4_con_3_4_name: "Gyeonggi1",
      cs_4_con_3_4_address:
        "2F, 94 Saeggot-ro, Geumchon-dong, Paju-si, Gyeonggi-do",
      cs_4_con_3_5_name: "Gyeonggi5",
      cs_4_con_3_5_address:
        "4F, 95 Dolmun-ro, Ssau-dong, Gimpo-si, Gyeonggi-do",
      cs_4_con_3_6_name: "Gyeonggi6",
      cs_4_con_3_6_address:
        "GL Plaza 203, 61 Muwon-ro 6beon-gil, Haengsin-dong, Deokyang-gu, Goyang-si, Gyeonggi-do",
      newsAnnouncement: "Announcement",
      newsNumber: "Number",
      newsTitle: "Title",
      newsDate: "Date",
      newsPost: "Post",
      recruitInfo: "Recruit",
      recruitTitle:
        "We welcome passionate individuals ready to grow together with SCG Solutions!",
      recruitProgress: "Process",
      recruitProgress_1: "Document Review",
      recruitProgress_2: "Staff Interview",
      recruitProgress_3: "Executives Interview",
      recruitProgress_4: "Final Pass",
      recruitClass: "Class",
      recruitType: "Type",
      recruitschedule: "Period",
      recruitPreferential: "Preferential",
      recruitTypeValue: "Intern / Newcommer / Experienced",
      recruitscheduleValue: "fixed bond / Occasional",
      recruitPreferentialValue:
        "Degree relevant to job / Experience / Certificate",
      recruitApply: "Apply",
      recruitApply_1: "E-mail Apply",
      recruitApply_2: "Online Apply",
      recruitApply_3: "Job Posting Site",
      recruitApplyDownload: "Job Application",
      benefits: "Benefits",
      benefitsDescripion:
        "SCG Solutions strives to improve the welfare of its executives and employees",
      benefits_con1: "Performance bonus system",
      benefits_con1_value: "Rewards based on organizational and",
      benefits_con1_value_2: "individual performance evaluations.",
      benefits_con2: "Recognition program",
      benefits_con2_value: "Regular selection of outstanding employees.",
      benefits_con3: "Education support",
      benefits_con3_value: "(Related personnel).",
      benefits_con4: "Communication expense support",
      benefits_con4_value: "(Based on job position and role).",
      benefits_con5: "Accidental insurance",
      benefits_con5_value:
        "Comprehensive accident insurance coverage for all employees,",
      benefits_con5_value_2:
        "providing compensation for accidents and daily life.",
      benefits_con6: "Health",
      benefits_con6_2: "check-up",
      benefits_con6_value: "Annual comprehensive health check-ups provided,",
      benefits_con6_value_2: "with discounted rates for family members.",
      benefits_con7: "Recreational facilities",
      benefits_con7_value:
        "Nationwide membership access to resorts and training centers,",
      benefits_con7_value_2: "with accommodation support.",
      benefits_con8: "Commemorative gift",
      benefits_con8_value: "Holidays/birth-related",
      benefits_con8_value_2: "benefits.",
      benefits_con9: "Condolence support",
      benefits_con9_value: "Condolence money/flower arrangements,",
      benefits_con9_value_2: "leave for condolences, condolence goods.",
      benefits_con10: "Vacation",
      benefits_con10_2: "rest",
      benefits_con10_value:
        "Annual leave/half-day leave/flexible half-day leave system,",
      benefits_con10_value_2: "provision of snacks in the break room.",
      recruitPosts: "Recruits",
      recruitTerm: "Recruit Period",
      recruitNewcomer: "Newcommer",
      recruitCareer: "Experienced",
      recruitNewCareer: "New/Exp",
      recruitIntern: "Intern",
      recruitPost: "Post",
      recruitStatus: "Status",
      recruitStatus_1: "Before Recruitment",
      recruitStatus_2: "Recruiting",
      recruitStatus_3: "Recruit Complet",
      supportClass: "Business Area",
      supportClass2: "Vendor",
      supportInquiry: "Inquiry",
      supportCon:
        "Building Management / Construction management / Material Supply Management / Service Center / Call Center / Electric Vehicle Charging Station Installation and Management",
      modalTitle: "Quotation",
      modalTitle2: "Inquiry",
      modalType: "Type",
      modalOther: "Other Inquiry",
      modalLabel: "Report / Suggestion",
      modalProductType: "Type of Product",
      modalInquiryType: "Type of Inquiry",
      modalCompany: "Company Name",
      modalManager: "Manager Name",
      modalEmail: "E-mail",
      modalName: "Name",
      modalContact: "Contact",
      modalContents: "Content",
      modalDell_1: "Server",
      modalDell_2: "Storage",
      modalDell_3: "Solution",
      modalDell_4: "Network",
      modalSeagate_1: "Hard Disk",
      modalBenq_1: "Electronic Whiteboards",
      modalArista_1: "Product Inquiry",
      modalService_1: "Service Inquiry",
      modalInformation_1: "Report",
      modalSuggestion_1: "Suggestion",
      modalCancle: "Cancel",
      modalSend: "Confirm",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ko",
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
